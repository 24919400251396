(function () {
    if(document.getElementById("countdown")) {
        const second = 1000,
            minute = second * 60,
            hour = minute * 60,
            day = hour * 24,
            week = day * 7;
        
        let today = new Date(),
            dd = String(today.getDate()).padStart(2, "0"),
            mm = String(today.getMonth() + 1).padStart(2, "0"),
            yyyy = today.getFullYear(),
            nextYear = yyyy + 1;

        var birthday =  document.getElementById("countdown").getAttribute('data-date');
        
        today = mm + "/" + dd + "/" + yyyy;
        
        const countDown = new Date(birthday).getTime(),
            x = setInterval(function() {    
        
            const now = new Date().getTime(),
                distance = countDown - now;

            if (distance < 0) {
                document.getElementById("countdown").innerHTML = "<h4>Countdown Complete.</h4><p><a href='/contact/' target='' class='btn btn--white btn--sm'>Need help? Contact Us</a></p>";
                clearInterval(x);
            } else {
                document.getElementById("weeks").innerText = Math.floor(distance / (week)),
                document.getElementById("days").innerText = Math.floor((distance % (week)) / (day)),
                document.getElementById("hours").innerText = Math.floor((distance % (day)) / (hour)),
                document.getElementById("minutes").innerText = Math.floor((distance % (hour)) / (minute));
            }
        }, 0)
    }
}());