jQuery(document).ready(function($) {
	$(".global-nav__item").on({
		mouseenter: function () {
			$(this).addClass('is-active');
			$(this).find('.global-nav__group').addClass('is-active');
		},
		mouseleave: function () {
			$(this).removeClass('is-active');
			$(this).find('.global-nav__group').removeClass('is-active');
		}
	});

	var headerHeight = $('header').height();
	$(window).scroll(function(){
		if ($(window).scrollTop() >= 60) {
			if(!$('.site-header').hasClass('has-hero')) {
				$('body').css('padding-top', headerHeight);
			}

			$('.site-header').addClass('site-header--fixed');
		}
		else {
			if(!$('.site-header').hasClass('has-hero')) {
				$('body').css('padding-top', 0);
			}
			$('.site-header').removeClass('site-header--fixed');
		}
	});
});
