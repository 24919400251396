(function($){
    $.fn.isInViewport = function() {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();

        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();

        return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    function statShow() {
        const counters = document.querySelectorAll('.statistics__box--title');
        counters.forEach(counter => {
            counter.innerText = '0'
            counter.dataset.count = 0;
            const updateCounter = () => {
                const target = +counter.getAttribute('data-target')
                const count = +counter.dataset.count

                const increment = target / 1200 // define increment as counter increase value / speed

                if (count < target) {
                    const newCount = Math.ceil(count + increment);
                    counter.dataset.count = newCount;
                    counter.innerText = numberWithCommas(newCount);
                    setTimeout(updateCounter, 1);
                } else {
                    counter.innerText = numberWithCommas(target);
                }
            }

            updateCounter();
        });
    }

    $(document).ready(function(){
        if($('.statistics__grid').length && $('.statistics__grid').isInViewport()) {
            statShow();
        }
    });

	var counter = 0;
    $(window).scroll(function(){
		if(counter == 0){
			if($('.statistics__grid').length && $('.statistics__grid').isInViewport()) {
				statShow();
				counter++;
			}
		}
    });
})(jQuery);
