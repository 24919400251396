import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

jQuery(document).ready(function($) {

	if ( typeof Swiper !== "undefined" ){

		// Logo carousel
		new Swiper('.logo-swiper', {
			modules: [Autoplay],
			slidesPerView: "2",
			loop: true,
            autoplay: {
                delay: 5000,
            },
			spaceBetween: 20,
			breakpoints: {
				1024: {
					slidesPerView: "5",
					spaceBetween: 30,
				},
			},
		});

		// Testimonial cards
		new Swiper('.testimonials-card-swiper', {
			modules: [Autoplay, Pagination, Navigation],
			slidesPerView: "1",
			loop: true,
            autoplay: {
                delay: 10000,
            },
			centeredSlides: true,
			spaceBetween: 30,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
			breakpoints: {
				968: {
					slidesPerView: "2",
					spaceBetween: 30,
				},
				1200: {
					slidesPerView: "3",
					spaceBetween: 35,
				},
			},
		});

		new Swiper('.testimonials-carousel-swiper', {
			modules: [Navigation],
			slidesPerView: "1",
			loop: true,
			centeredSlides: true,
			spaceBetween: 30,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			breakpoints: {
				1024: {
					spaceBetween: 40,
				},
			},
		});

		new Swiper('.timeline-swiper', {
			modules: [Navigation],
			slidesPerView: 1,
			navigation: {
				nextEl: '#timeline-next',
				prevEl: '#timeline-prev',
			},
			breakpoints: {
				800: {
					slidesPerView: 3,
					centeredSlides: true,
				},
			},
		});

	}

});
