import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init({
  duration: 750,
  delay: 100,
  easing: 'ease-in-out',
  once: true,
  disable: 'mobile'
});
